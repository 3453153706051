.home-video {
    position: absolute;
    display: block;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 101;
    .landing-text {
        position: absolute;
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        align-items: flex-start;
        justify-content: space-between;
        text-align: flex-start;
        width: 100vw;
        height: var(--app-height);
        padding: 1rem 0 1rem 1.5rem;
        z-index: 102;
        background-color: transparent;
        &-top {
            width: 50vw;
        }
        &-small{
            width: 100vw;
            margin-left: 0.2rem;
            margin-top: -1rem;
        }
        &-contact {
            position: absolute;
            display: flex;
            flex-direction: column;
            width: 12rem;
            margin-left: 0rem;
            bottom: 4rem;
            p {
                padding-top: 3vh;
            }
            p, a {
                font-weight: 600;
                font-size: 0.7rem;
                text-decoration: none;
                color: $white;
            }
            a:hover {
                color: $primary;
                background-color: $white;
            }
        }
        h1, h3, h4 {
            color: $white;
        }
        h1 {
            font-size: 3.5rem;
            line-height: 5rem;
            letter-spacing: -0.15rem;
            width: 100%;
        }
        h3 {
            font-size: 1.3rem;
            line-height: 0.2rem;
            letter-spacing: -0.05rem;
        }
        h4 {
            font-size: 4vmin;
            line-height: 2.3vmin;
            letter-spacing: -0.05rem;
            margin: 0;
        }
    }
    .scroll-btn {
        position: absolute;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        right: -1.5rem;
        top: calc(var(--app-height) - 7rem);
        z-index: 102;
        rotate: -90deg;
        cursor: pointer;
        p {
            font-size: 2rem;
            letter-spacing: -0.04rem;
            font-weight: 600;
            margin: 0 0.25rem 0 0;
            color: $white;
        }
        @keyframes btn-arrows {
            0% { transform: translateX(0) }
            50% { transform: translateX(0.75rem) }
            100% { transform: translateX(0) }
        }
        svg {
            height: 1.9rem;
            width: 2.25rem;
            padding-top: 0.2rem;
            margin-left: 0.2rem;
            fill: $white;
            animation: btn-arrows 2s ease-in-out infinite;
        }
        :hover {
            color: $primary;
        }
    }
    .dwn-btn {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        z-index: 102;
        top: calc(var(--app-height) - 3.5rem);
        width: 6rem;
        height: 3rem;
        left: calc(50% - 3rem);
        padding: 0;
        background-color: transparent;
        border: none;
        cursor: pointer;
        
        span {
            display: block;
            height: 8px;
            width: 2.5rem;
            background-color: $white;
            border-radius: 4px;
        }

        :nth-child(1) {
            transition: 0.5;
            -webkit-transform: translate( 6px, 23px ) rotate( 30deg );
                -ms-transform: translate( 6px, 23px ) rotate( 30deg );
                    transform: translate( 6px, 23px ) rotate( 30deg );
        }

        :nth-child(2) {
            transition: 0.5;
            -webkit-transform: translate( -6px, 23px ) rotate( -30deg );
                -ms-transform: translate( -6px, 23px ) rotate( -30deg );
                    transform: translate( -6px, 23px ) rotate( -30deg );
        }
    }
    .dwn-btn:hover {
        height: 5rem;
        -webkit-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        :nth-child(1) {
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            -webkit-transform: translate( 6px, 23px ) rotate( -30deg );
                -ms-transform: translate( 6px, 23px ) rotate( -30deg );
                    transform: translate( 6px, 23px ) rotate( -30deg );
        }

        :nth-child(2) {
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            -webkit-transform: translate( -6px, 23px ) rotate( 30deg );
                -ms-transform: translate( -6px, 23px ) rotate( 30deg );
                    transform: translate( -6px, 23px ) rotate( 30deg );
        }
    }
    .static {
        position: absolute;
        height: var(--app-height);
        min-width: 100vw;
        box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.6);
    }
    .gradient {
        position: absolute;
        height: var(--app-height);
        width: 100vw;
        background: linear-gradient($motion, transparent 60%, $motion);
        opacity: 50%;
        z-index: 100;
    }
    .home-playr-mobile {
        position: relative;
        overflow: hidden;
        z-index: 99;
        top: -5vw;
        left: 50%;
        width: calc(var(--vw, 1vw) * 101);
        height: calc(var(--vw, 1vw) * 100); /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: calc(var(--vh, 1vh) * 100);
        min-width: calc(var(--vh, 1vh) * 10); /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        transform: translate(-50%, 0%);
        pointer-events: none;
        background-size: cover;
    }
    .home-playr-desktop {
        position: relative;
        overflow: hidden;
        z-index: 99;
        top: 0;
        left: 50%;
        width: calc(var(--vw, 1vw) * 100);
        height: calc(var(--vw, 1vw) * 56.25); /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: calc(var(--vh, 1vh) * 100);
        min-width: calc(var(--vh, 1vh) * 177.77); /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        width: 100vw;
        height: 56.25vw;
        min-height: 100vh;
        min-height: var(--app-height);
        min-width: 177.77vh;
        transform: translate(-50%, 0%);
        pointer-events: none;
        background-size: cover;
    }
    .video-spacer {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        height: 100vh;
        width: 100vw;
    }
}

.home-logo {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: var(--app-height);
    width: 100vw;
    background-color: $primary;
    z-index: 100;
    .logo {
        position: fixed;
        fill: $white;
        width: 105vw;
        max-width: 500px;
        height: 105vw;
        right: auto;
        z-index: 100;
        bottom: -50vw;
        padding: 0;
    }
}

@media (orientation: landscape) {
    .home-video {
        .home-playr-mobile {
            position: relative;
            overflow: hidden;
            z-index: 101;
            top: -5vw;
            left: 50%;
            width: calc(var(--vw, 1vw) * 237);
            height: calc(var(--vw, 1vw) * 56.25); /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: calc(var(--vh, 1vh) * 100);
            min-width: calc(var(--vh, 1vh) * 177.77); /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            transform: translate(-50%, 0%);
            pointer-events: none;
            background-size: cover;
        }
    }
}
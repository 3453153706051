.motion-contents::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.motion-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: var(--app-height);
    width: 100vw;
    z-index: 90;

    .motion-list {
        position: fixed;
        height: var(--app-height);
        width: 100%;
        margin: 0;
        padding: 0;
        list-style-type: none;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-scroll-snap-type: y mandatory;
            scroll-snap-type: y mandatory;
    }
}

.motion-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    scroll-snap-align: center;

    .motion-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    a {
        color: $white;
        text-decoration: none;
    }

    .project-title {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100vw;
        height: 56.25vw;
        z-index: 91;
        cursor: pointer;

        h1 {
            font-size: var(--font-size-xxxl);
            line-height: var(--font-size-xxxl);
            letter-spacing: -0.1rem;
            margin: 0;
        }
        h2 {
            font-size: var(--font-size-xxl);
            line-height: var(--font-size-xxl);
            letter-spacing: -0.1rem;
            margin: 0;
        }
    }

    .motion-playr,
    .motion-holder {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-self: center;
        justify-self: center;
        justify-content: center;
        width: 100vw;
        max-height: 56.25vw;
        z-index: 90;
        aspect-ratio: 16/9;
        aspect-ratio: 1.7778;
        overflow: hidden;
    }

    .motion-holder {
        position: absolute;
        align-self: center;
        justify-self: center;
        z-index: 89;
        background-image: url("https://upload.wikimedia.org/wikipedia/commons/6/66/SMPTE_Color_Bars.svg");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .film-grabs {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: calc(100vw - 0.5rem);
        padding: 0 auto;
        top: 1rem;
    }
}

.motion-project {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;;
    z-index: 98;
    height: var(--app-height);
    width: 100%;
    padding: 0;
    background-color: $motion;

    .motion-project-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 1rem 0 0 1rem;
        height: 4rem;
        text-align: left;
        color: $white;

        h1 {
            font-size: 1.75rem;
            line-height: 3rem;
        }
    }

    .close-btn {
        position: absolute;
        width: 6.5rem;
        height: 4rem;
        text-align: left;
        top: 0;
        left: 0;
        margin: 0 0 0 0;
        padding: 0 0 0 0.8rem;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        z-index: 102;

        h5 {
            font-size: 2rem;
            line-height: 0rem;
            letter-spacing: -0.1rem;
            margin: 0;
            padding: 0;
            color: $primary;
        }
    }

    .contents {
        overflow: hidden;
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        height: var(--app-height);
    }

    &-playr *, .video-spinner {
        position: relative;
        margin: 0 1rem 0 1rem;
        width: calc(100vw - 2rem);
        height: calc(var(--app-height) - 9.5rem);
        z-index: 100;
    }

    .video-spinner {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        z-index: 90;
        .logo-container {
            width: 5rem;
            height: 5rem;
            .logo {
                width: inherit;
                height: inherit;
                margin: 0;
                padding: 0;
                right: auto;
            }
        }
        
    }

    &-info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        position: absolute;
        width: 100vw;
        height: auto;
        max-height: 40%;
        margin: 0;
        bottom: 4.5rem;
        overflow-y: scroll;
        overflow-x: hidden;
        border-top: 0.35rem solid $white;
        z-index: 101;
        background-color: rgba(3,7,8,0.5);

        #info-text {
            padding: 0.5rem 1rem;
            color: $white;
        }

        .client {
            margin: 0;
            height: 7rem;
        }

        .credits {
            border-bottom: 0.35rem solid $white;
            &-text {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                -ms-flex-line-pack: start;
                    align-content: flex-start;
                height: 7rem;
                padding-bottom: 0.7rem;
            }
        }
        .other-videos {
            border-bottom: 0.35rem solid $white;
            .videos {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
                -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                -ms-flex-pack: distribute;
                    justify-content: space-around;
                width: 100%;
                z-index: 100;
                margin-bottom: 1rem;
                .video-holder {
                    width: 30vw;
                    cursor: pointer;
                    button {
                        position: absolute;
                        height: 30vh;
                        width: inherit;
                        border: none;
                        z-index: 100;
                        background-color: transparent;
                    }
                    .other-videos-playr * {
                        position: relative;
                        max-height: 30vh;
                        width: 100%;
                        z-index: 90;
                    }
                }
            }
        }
        h2 {
            font-size: var(--font-size-lg);
            line-height: var(--font-size-lg);
            margin: var(--font-size-sm) 0;
        }
        h3 {
            font-size: var(--font-size-xl);
            line-height: var(--font-size-lg);
            margin: var(--font-size-base) 0;
        }
        h4 {
            font-size: var(--font-size-base);
            line-height: var(--font-size-base);
            margin: var(--font-size-sm) 0;
        }
        p {
            font-size: var(--font-size-sm);
            line-height: var(--font-size-sm);
            width: calc(50vw - 1rem);
            margin: 0.2rem 0;
        }
    }

    .info-button {
        position: fixed;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: 100vw;
        height: 4.5rem;
        margin: 0;
        padding: 0 0 0 1rem;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        bottom: 0;
        border-left-style: 0;
        z-index: 102;
        background-color: $motion;

        h1 {
            font-size: 1.75rem;
            color: $white;
        }

        .info-caret {
            width: 3rem;
            -webkit-transition: .6s;
            -o-transition: .6s;
            transition: .6s;
            span {
                position: relative;
                display: block;
                height: 4px;
                width: 1.25rem;
                background-color: $white;
                border-radius: 2px;
                margin-top: 0;
            }

            :nth-child(1) {
                -webkit-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                -webkit-transform: translate(0.5rem, 0.25rem) rotate(45deg);
                    -ms-transform: translate(0.5rem, 0.25rem) rotate(45deg);
                        transform: translate(0.5rem, 0.25rem) rotate(45deg);
            }

            :nth-child(2) {
                -webkit-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                -webkit-transform: translate(1.25rem, 0rem) rotate(-45deg);
                    -ms-transform: translate(1.25rem, 0rem) rotate(-45deg);
                        transform: translate(1.25rem, 0rem) rotate(-45deg);
            }
        }

        .info-caret.active {
            -webkit-transition: 0.5s;
            -o-transition: 0.5s;
            transition: 0.5s;
            span {
                width: 1.5rem;
                background-color: $primary;
            }
            :nth-child(1) {
                -webkit-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                -webkit-transform: translate(0.5rem, 0.15rem) rotate(-45deg);
                    -ms-transform: translate(0.5rem, 0.15rem) rotate(-45deg);
                        transform: translate(0.5rem, 0.15rem) rotate(-45deg);
            }

            :nth-child(2) {
                -webkit-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                -webkit-transform: translate(0.51rem, -0.1rem) rotate(45deg);
                    -ms-transform: translate(0.51rem, -0.1rem) rotate(45deg);
                        transform: translate(0.51rem, -0.1rem) rotate(45deg);
            }
        }
    }
}

@media (orientation: landscape)  {
    .motion-wrapper {
        .motion-item {
            height: 100%;
            .motion-playr, .motion-holder, .project-title {
                width: 58vmax;
                max-height: 32.625vmax;
                transition: 0.3s;
            }
            .project-title {
                padding: 0 17.5vmax;
            }
            .film-grabs {
                align-self: center;
                width: 70vmax;
                img {
                    max-height: 16vh;
                }
            }
        }
        .motion-project {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            .close-btn {
                margin-right: 100%;
            }
        }
    }
}

.viewer, .project-viewer {
    position: absolute;
    height: calc(var(--app-height) - 4.5rem);
    width: 100vw;
    margin-top: 4.5rem;
}

.project-viewer {
    height: calc(var(--app-height) - 4.5rem);
    width: 100vw;
    margin-top: 4.5rem;
    .viewer-image {
        height: calc(var(--app-height) - 11rem);
        .fullscreen {
            margin-top: -1.5rem;
        }
        .viewer-grid {
            height: calc(var(--app-height) - 9.25rem);
        }
        .slick-slide {
            .slide {
                .slide-images {
                    img {
                        max-height: calc(100vh - 11rem);
                    }
                }
            }
        }
    }
}

.viewer-image {
    position: fixed;
    height: calc(var(--app-height) - 9rem);
    width: 100vw;

    .fullscreen {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        height: var(--app-height);
        width: 100vw;
        padding: 0 0 1rem 0;
        z-index: 101;
        background-color: $white;
        top: -4.5rem;

        .close-btn {
            position: absolute;
            width: 2.5rem;
            height: 2.5rem;
            margin: 1rem 0 0 0.5rem;
            padding: 0;
            border: none;
            outline: none;
            cursor: pointer;
            background-color: transparent;
            top: 0;
            left: 0;
            z-index: 102;

            span {
                position: absolute;
                display: block;
                height: 3px;
                width: 1.5rem;
                background-color: $black;
                border-radius: 0px;
                margin-top: 0;
            }

            :nth-child(1) {
                -webkit-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                -webkit-transform: translate(0.5rem, 0rem) rotate(45deg);
                    -ms-transform: translate(0.5rem, 0rem) rotate(45deg);
                        transform: translate(0.5rem, 0rem) rotate(45deg);
            }

            :nth-child(2) {
                -webkit-transition: .3s;
                -o-transition: .3s;
                transition: .3s;
                -webkit-transform: translate(0.5rem, 0rem) rotate(-45deg);
                    -ms-transform: translate(0.5rem, 0rem) rotate(-45deg);
                        transform: translate(0.5rem, 0rem) rotate(-45deg);
            }
        }

        &-img {
            max-height: calc(var(--app-height) - 9rem);
            padding: 0 auto;
            -o-object-fit: contain;
            object-fit: contain;
            cursor: pointer;
        }
    }

    .viewer-grid {
        position: absolute;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: flex-start;
        overflow: hidden;
        overflow-y: scroll;
        height: calc(var(--app-height) - 9rem);
        width: calc(100vw - 1rem);
        padding: 0 0.5rem;
        z-index: 90;
        background-color: $white;
        
        .slide {
            position: relative;
            padding: 0 0.5rem 1rem 0.5rem;
            height: 11vmax;
            max-height: 300px;
            width: auto;
            border: none;
            cursor: pointer;

            .grid-plyr-btn {
                position: absolute;
                height: 100%;
                aspect-ratio: 16/9;
                z-index: 91;
            }

            .grid-plyr {
                height: 100%;
                aspect-ratio: 16/9;
                z-index: 90;
                background-image: url("https://upload.wikimedia.org/wikipedia/commons/6/66/SMPTE_Color_Bars.svg");
                background-size: cover;
            }

            img {
                height: 100%;
            }

            :nth-child(1) {
                padding-right: 0.3rem;
            }
        }
    }

    .slick-slider {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: inherit;
        height: 100%;
        z-index: 89;

        .carousel-chevron {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-flow: column;
            flex-flow: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 2rem;
            height: 60vh;
            padding: 0;
            cursor: pointer;
            background-color: transparent;
            outline: none;
            border: none;
    
            svg {
                margin: 0 auto;
                width: .8rem;
            }
        }

        .slick-list {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            padding: 0 2rem;
            width: inherit;
    
            .slick-track {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                width: 100%;
            }
        }
    }

    .slick-slide {

        .slide {
            position: relative;
            display: -webkit-box !important;
            display: -ms-flexbox !important;
            display: flex !important;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -ms-flex-line-pack: center;
            align-content: center;
            border: none;
            outline: none;
            width: 100%;
            height: 100%;

            .slide-playr {
                z-index: 100;
                height: calc(100vh - 14.2rem);
                margin-top: 7.2rem;

                * {
                position: relative;
                height: inherit;
                max-height: inherit;
                width: 90vw;
                aspect-ratio: 16/9;
                }
            }

            .slide-images {
                position: relative;
                display: -webkit-box !important;
                display: -ms-flexbox !important;
                display: flex !important;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-wrap: nowrap;
                flex-wrap: nowrap;
                -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                border: none;
                outline: none;
                width: auto;
                max-width: 100%;
                height: 100vh;

                img {
                    display: block;
                    height: auto;
                    max-height: calc(var(--app-height) - 9.1rem);
                    width: 100%;
                    -o-object-fit: contain;
                    object-fit: contain;
                    border: none;
                    margin: 0;
                    padding: 0;
                    cursor: pointer;
                    z-index: 92;
                }

                img:nth-child(1):nth-last-child(2),
                img:nth-child(2):nth-last-child(1) {
                    width: auto !important;
                    max-width: calc(50% - 0.5rem);
                }
            }
        }
    }
}

.viewer-footer {
    position: fixed;
    display: flex;
    height: 4.5rem;
    width: calc(100vw - 72px);
    bottom: 0;
    z-index: 92;

    .footer-item {
        display: column;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: $text2;
        font-size: 0.75em;
        font-weight: 600;
        line-height: 0.9em;
    }

    .project-mgmt {
        position: relative;
        width: calc(30% - 1rem);
        height: 100%;
        padding-left: 1rem;

        .project-btn {
            text-decoration: none;
        }

        .grid-btn {
            cursor: pointer;
        }

        p {
            color: $gray-600;
            margin: 0.2rem 0;
        }

        p:hover {
            color: $black;
        }
    }

    .description {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        width: calc(70% - 1rem);
        height: 100%;
        padding-right: 1rem;
        line-height: 1.25em;

        p {
            color: $gray-600;
            padding-left: 2rem;
        }
    }
}

@media (min-width:1000px) {
    .viewer-image {
        .viewer-grid {
            .slide {
                max-height: 90px;
                padding: 0 0.2rem 1rem 0.2rem;
                transition: 0.5s;
            }
        }
    }
}

@media (max-width:600px) {
    .viewer-image {
        .viewer-grid {
            justify-content: space-around;
            width: calc(100vw - 0.5rem);
            padding: 0 0.25rem;

            .slide {
                max-height: 21vmin;
                padding: 0 0.2rem 0.5rem 0.2rem;
            }
        }
    }
}

@media (max-height: 500px) {
    .viewer-image {
        .slick-slide {
            .slide {
                .slide-playr {
                    height: 57vh;
                    margin-top: 4.5rem;
                }
            }
        }
    }
}
.stills-page {
    z-index: 80;

    .slider-wrapper {
        position: absolute;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        width: 100vw;
        height: var(--app-height);
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: $white;
    
        .page-title {
            position: relative;
            height: 4.5rem;
            width: 100vw;
            padding: 0;
            text-align: left;
            z-index: 82;

            h1 {
                padding: 1.5rem 0 0 1rem;
            }
        }
    
        .slider-thumbs {
            position: absolute;
            display: block;
            background-color: $white;
            height: calc(var(--app-height) - 9rem);
            padding: 4.5rem 0 4.5rem 0;
            width: 100vw;
            opacity: 100;
        
            .gallery {
                overflow: hidden;
                width: auto;
                height: 100%;
                padding: 0 0.5rem;
                overflow-y: scroll;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                -ms-flex-pack: distribute;
                justify-content: space-around;
        
                .gallery-slide {
                    height: 17vmin;
                    max-height: 120px;
                    padding: 0 0.5rem 1rem 0.5rem;
                    cursor: pointer;
                    z-index: 91;

                    .gallery-plyr-btn {
                        position: absolute;
                        aspect-ratio: 16/9;
                        height: 17vmin;
                        max-height: 120px;
                        z-index: 92;
                    }

                    .gallery-plyr {
                        position: relative;
                        aspect-ratio: 16/9;
                        height: 17vmin;
                        max-height: 120px;
                        z-index: 90;
                    }
        
                    img {
                        height: 100%;
                    }
        
                    :nth-child(1) {
                        padding-right: 0.3rem;
                    }
                }
            }
        }
    
        .fullscreen {
            position: absolute;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            height: var(--app-height);
            width: 100vw;
            padding: 0 0 1rem 0;
            z-index: 101;
            background-color: $white;
    
            .close-btn {
                position: absolute;
                width: 2.5rem;
                height: 2.5rem;
                margin: 1rem 0 0 0.5rem;
                padding: 0;
                border: none;
                outline: none;
                cursor: pointer;
                background-color: transparent;
                top: 0;
                left: 0;
                z-index: 102;
    
                span {
                    position: absolute;
                    display: block;
                    height: 3px;
                    width: 1.5rem;
                    background-color: $black;
                    border-radius: 0px;
                    margin-top: 0;
                }
    
                :nth-child(1) {
                    -webkit-transition: .3s;
                    -o-transition: .3s;
                    transition: .3s;
                    -webkit-transform: translate(0.5rem, 0rem) rotate(45deg);
                        -ms-transform: translate(0.5rem, 0rem) rotate(45deg);
                            transform: translate(0.5rem, 0rem) rotate(45deg);
                }
    
                :nth-child(2) {
                    -webkit-transition: .3s;
                    -o-transition: .3s;
                    transition: .3s;
                    -webkit-transform: translate(0.5rem, 0rem) rotate(-45deg);
                        -ms-transform: translate(0.5rem, 0rem) rotate(-45deg);
                            transform: translate(0.5rem, 0rem) rotate(-45deg);
                }
            }
    
            &-img {
                max-height: calc(var(--app-height) - 9rem);
                padding: 0 auto;
                -o-object-fit: contain;
                object-fit: contain;
                cursor: pointer;
            }
        }
    
        .slider {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            height: 50%;
            width: 100vw;
            margin: 0rem 0 0 0;
            z-index: 80;
        
            .slick-slider {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                width: inherit;
                height: 100%;
    
                .carousel-chevron {
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-flow: column;
                    flex-flow: column;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    width: 2rem;
                    height: 60vh;
                    padding: 0;
                    cursor: pointer;
                    background-color: transparent;
                    outline: none;
                    border: none;
            
                    svg {
                        margin: 0 auto;
                        width: .8rem;
                    }
                }
    
                .slick-list {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    padding: 0 2rem;
                    width: inherit;
            
                    .slick-track {
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-align: center;
                            -ms-flex-align: center;
                                align-items: center;
                        width: 100%;
                    }
                }
            }
        
            .slick-slide {
        
                .image-slide {
                    position: relative;
                    display: -webkit-box !important;
                    display: -ms-flexbox !important;
                    display: flex !important;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                    flex-direction: row;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    -ms-flex-line-pack: center;
                    align-content: center;
                    border: none;
                    outline: none;
                    width: 100%;
                    height: 100%;

                    .slide-playr {
                        z-index: 100;
                        height: calc(100vh - 8.5rem);
                        margin-top: 4.5rem;

                        * {
                        position: relative;
                        height: inherit;
                        max-height: inherit;
                        width: 80vw;
                        aspect-ratio: 16/9;
                        }
                    }
        
                    .slide-image {
                        position: relative;
                        display: -webkit-box !important;
                        display: -ms-flexbox !important;
                        display: flex !important;
                        -webkit-box-orient: horizontal;
                        -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                        flex-direction: row;
                        -ms-flex-wrap: nowrap;
                        flex-wrap: nowrap;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        border: none;
                        outline: none;
                        width: auto;
                        max-width: 100%;
                        height: 100vh;
        
                        img {
                            display: block;
                            height: auto;
                            max-height: calc(var(--app-height) - 9rem);
                            width: 100%;
                            -o-object-fit: contain;
                            object-fit: contain;
                            border: none;
                            margin: 0;
                            padding: 0;
                            cursor: pointer;
                            z-index: 92;
                        }
        
                        img:nth-child(1):nth-last-child(2),
                        img:nth-child(2):nth-last-child(1) {
                            width: auto !important;
                            max-width: calc(50% - 0.5rem);
                        }
                    }
                }
            }
        }
    
        .slider-dock {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            width: 100%;
            height: 4.5rem;
        
            .dock-buttons {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                        flex-direction: column;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                height: 4.5rem;
                padding: 0 0 0 0.75rem;
                z-index: 82;
                
                .gallery-button, .filter-button, .button-spacer, .project-button, .back-button {
                    position: relative;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                        -ms-flex-align: center;
                            align-items: center;
                    height: 1rem;
                    width: auto;
                    margin: 0;
                    padding-left: 0.4rem;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
    
                    h4 {
                        font-size: 0.75rem;
                        margin: 0;
                        color: rgb(134, 134, 134);
                    }
                    :hover {
                        color: black;
                    }
                }

                .filter-buttons {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                            flex-direction: row;
                    -webkit-box-pack: start;
                        -ms-flex-pack: start;
                            justify-content: flex-start;
                    width: 9rem;
                    padding: 0 0.4rem;

                    .filter-button {
                        width: auto;
                        padding: 0;
                    }
        
                    .button-spacer {
                        margin: 0;
                        padding: 0 0.5rem;
        
                        :hover {
                            color: rgb(134, 134, 134);
                        }
                    }

                    #close {
                        padding: 0 0.5rem;
                    }
                }
    
                .back-button {
                    width: 1rem;
                }
    
                .project-button, .back-button {
                    width: 5rem;
                    padding-left: 0.4rem;
                    text-decoration: none;
                }
    
                .filter-buttons {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: horizontal;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: row;
                            flex-direction: row;
                }
            }
            
    
            .img-caption {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-pack: end;
                -ms-flex-pack: end;
                justify-content: flex-end;
                height: 4.5rem;
                width: calc(100vw - 10.5rem);
                margin: 0 3.5rem 0 0;
                padding: 1.25rem 1rem 0 0;
    
                &.none {
                    display: none;
                }
    
                p {
                    font-size: 0.6rem;
                    line-height: 0.6rem;
                    text-align: right;
                    width: auto;
                    margin-left: 1rem;
                }
            }
    
        }
    }
}

.project-page {
    position: absolute;
    background-color: $white;
    z-index: 90;

    .slider-wrapper {

        .page-title {
            position: relative;
            height: 7rem;
            width: calc(100vw - 4.5rem);
            padding: .5rem 0 0 0;
            text-align: left;
            z-index: 95;

            h2 {
                font-size: 1.6rem;
                line-height: 1.5rem;
                letter-spacing: -0.05rem;
                padding: 0rem 0 0 1rem;
                margin: 1rem 0 0 0;
            }
            h3 {
                padding: 0rem 0 0 1rem;
                margin: 0;
                letter-spacing: -0.05rem;
            }
        }

        .slider-thumbs {
            height: calc(var(--app-height) - 11.5rem);
            padding: 7.5rem 0 4.5rem 0;

            .gallery {
                height: 100%;
                width: 80vw;
                padding: 0 10vw;
                align-content: start;

                .gallery-slide {
                    height: 25vmin;
                    max-height: 150px;
                    padding: 0 0.5rem 1rem 0.5rem;
                    cursor: pointer;
                }
            }
        }

        .slider {
            z-index: 90;

            .slick-slide {

                .image-slide {

                    .slide-image {
                        margin-top: 0rem;

                        img {
                            max-height: calc(var(--app-height) - 12.5rem)
                        }
                    }
                }
            }
        }

        .slider-dock {
            z-index: 95;

            .dock-button {
                z-index: 95;
            }
        }
    }
}

@media (min-width:700px)  {
    .slider {
        .carousel-chevron {
            margin: 0 1rem;
        }
    }
}

@media (max-height:550px)  {
    .slider {
        .slick-slide {
            .image-slide {
                .slide-image {
                    img {
                        max-height: 55vh;
                    }
                }
            }
        }
    }
}

@media (max-width:550px) {
    .slider-wrapper {
        .project-title {
            h2 {
                font-size: 0.9rem;
                padding-top: 0.5rem;
            }
        }
    }

    .slider-thumbs {
        .gallery {
            .gallery-slide {
                height: 10vh;
                padding: 0 0.2rem 1rem 0.2rem;
            }
        }
    }
}
.about {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100vw;
    height: --app-height;
    padding: 0;

    .pagetitle {
        background-color: $white;
        z-index: 97;
        width: 100vw;
    }

    h2 {
        font-size: 1.3rem;
        letter-spacing: -0.05rem;
    }
    
    .self-portraits {
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        z-index: 70;

        .self-portrait {
            max-width: 60vh;
            max-height: 60vw;
            opacity: 40%;
        }
    }

    .bio {
        padding: 5rem 2rem 0 1rem;
        z-index: 95;

        p {
            font-size: 1.2rem;
            line-height: 1.2rem;
            font-weight: 700;
            letter-spacing: -0.03rem;
        }
    }

    .client-contact {
        display: flex;
        padding: 0 0 0 0rem;
        margin-bottom: 2rem;
        z-index: 96;

        h2 {
            margin: 1rem 0 1.6rem 0;
            font-size: 1.2rem;
        }

        p {
            font-size: 0.8rem;
            line-height: 1rem;
            font-weight: 700;
            color: black;
            height: 1rem;
            margin: 0.2rem 0;

            a {
                text-decoration: none;
                color: black;
            }

            a:hover {
                color: $primary;
            }
        }

        .clients, .contact {
            margin: 0rem 1rem 2rem 0;
            padding-left: 1rem;
            width: 40vw;
        }
    }
}

@media (orientation: landscape) {
    .about {
        .bio {
            max-width: 50vw;
            padding: 5rem 0 0 1rem;
        }
        .client-contact {
            display: flex;
            flex-direction: column;
            margin-top: 5rem;
            .contact, .clients {
                width: 30vw;
            }
        }
    }
}

@media (max-width: 390px) {
    .about {
        .self-portraits {
            .self-portrait {
                max-width: 60vh;
                opacity: 40%;
            }
        }
        .client-contact {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .contact {
                width: 50%;
            }
            .clients {
                width: 50%;
            }
        }
    }
}
:root {
    --font-size-sm: clamp(0.66rem, 0.42vw + 0.55rem, 0.89rem);
    --font-size-base: clamp(0.88rem, 0.57vw + 0.73rem, 1.19rem);
    --font-size-md: clamp(1.16rem, 0.76vw + 0.97rem, 1.58rem);
    --font-size-lg: clamp(1.55rem, 1.02vw + 1.29rem, 2.11rem);
    --font-size-xl: clamp(2.06rem, 1.37vw + 1.72rem, 2.81rem);
    --font-size-xxl: clamp(1.74rem, 0.84vw + 2.28rem, 3.75rem);
    --font-size-xxxl: clamp(3.64rem, 2.47vw + 3.4rem, 5rem);
}

// Typography \\

@import url('https://fonts.googleapis.com/css2?family=Aclonica&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@500;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&display=swap');


$font-family-sans-serif:      "Aclonica", "Roboto", sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
$font-family-base:            $font-family-sans-serif ;
// stylelint-enable value-keyword-case

$font-size-base:              1rem ; // Assumes the browser default, typically `16px`

// Fonts

$title: "Aclonica", sans-serif;
$text: "Roboto", sans-serif;
$title2: 'Alegreya Sans SC', sans-serif;
$text2: 'Heebo', sans-serif;
$title3: 'Josefin Sans', sans-serif;

// System

h1 {
    font-family: $text2;
    margin: 0;
}


// Components

.page-title {
    line-height: 1.5rem;
    letter-spacing: -0.12rem;
}

.pagetitle {
    line-height: 1.5rem;
    letter-spacing: -0.12rem;
}

.navmenu-map {

    h1 {
        font-family: $text2;
        font-weight: 700;
        font-size: 3rem;
        line-height: 2.75rem;
        letter-spacing: -0.04rem;
        color: $white;
        cursor: pointer;
        -webkit-transition-duration: 300ms;
             -o-transition-duration: 300ms;
                transition-duration: 300ms;
    }
}

.img-caption {
    font-size: .6rem;
    font-weight: 800;
    line-height: 1rem;
    color: gray;
}

.project-page {
    height: 100vh;
    width: 100vw;
    background-color: $white;

    .pagetitle {
        font-size: 1.4rem;
        letter-spacing: -0.04rem;
        padding: 1.2rem 1rem 0.8rem 1rem;
    }
    .project-client {
        position: fixed;
        font-size: 1rem;
        padding: 1.6rem 1rem 1rem 1rem;
    }
    .project-viewer {
        .viewer-image {
            padding-top: 1rem;
            .viewer-grid {
                height: calc(var(--app-height) - 10rem);
            }
        }
    }
}
.logo {
    position: fixed;
    right: 0;
    z-index: 100;
    padding: 1rem 1rem;
    height: 2.3rem;
    fill: $primary;
}

.menu-btn {
    position: fixed;
    height: 4.5rem;
    width: 66px;
    bottom: 0;
    right: 0;
    padding: 0 1rem;
    margin: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    z-index: 100;

    span {
        display: block;
        height: 3px;
        width: 33px;
        background-color: $primary;
        border-radius: 3px;
    }

    :nth-child(1) {
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        -webkit-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
                transform: rotate(90deg);
    }

    :nth-child(2) {
        -webkit-transform: translateY(-3px);
            -ms-transform: translateY(-3px);
                transform: translateY(-3px);
    }
}

.menu-btn.active {
    :nth-child(1) {
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    :nth-child(2) {
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        -webkit-transform: rotate(-180deg) translateY(4px);
            -ms-transform: rotate(-180deg) translateY(4px);
                transform: rotate(-180deg) translateY(4px);
    }
}

.menu-btn.nav-close {
    :nth-child(1) {
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
    }

    :nth-child(2) {
        -webkit-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        -webkit-transform: rotate(-45deg) translate(4.5px, -4px);
            -ms-transform: rotate(-45deg) translate(4.5px, -4px);
                transform: rotate(-45deg) translate(4.5px, -4px);
    }
}

.navmenu {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    height: 100vh;
    width: 100vw;
    height: var(--app-height);
    padding: 0 0 0 0;
    margin: 0;
    top: 0px;
    right: 0px;
    z-index: 99;
    li {
        height: var(--app-height);
        width: 16vmin;
        border-right: 0.3rem solid #000;
        padding-right: 0.1rem;
        list-style: none;
        background-color: $white;
        a {
            text-decoration: none;
            h1 {
                font-size: 11vmin;
                line-height: 15vmin;
                letter-spacing: -0.1rem;
                color: #000;
                padding: 2rem 0 0 0;
                -webkit-writing-mode: vertical-lr;
                    -ms-writing-mode: tb-lr;
                        writing-mode: vertical-lr;
                -webkit-text-orientation: sideways;
                        text-orientation: sideways;
            }
        }
    }
}
.slide-in-up {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--app-height);
    background: $black;
    transform-origin: bottom;
    z-index: 102;
}

.slide-out-up {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--app-height);
    background: $primary;
    transform-origin: top;
    z-index: 101;
}

.slide-in-down {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--app-height);
    background: $primary;
    transform-origin: top;
    z-index: 102;
}

.slide-out-down {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--app-height);
    background: $primary;
    transform-origin: bottom;
    z-index: 102;
}
:root {
  --app-height: 100%;
}

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    rgb(250, 253, 255) ;
$gray-100: #f8f9fa ;
$gray-200: #e9ecef ;
$gray-300: #dee2e6 ;
$gray-400: #ced4da ;
$gray-500: #adb5bd ;
$gray-600: #6c757d ;
$gray-700: #495057 ;
$gray-800: #343a40 ;
$gray-900: #212529 ;
$black:    #000 ;
$motion:   #030708 ;

$grays: () ;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #007bff ;
$indigo:  #6610f2 ;
$purple:  #6f42c1 ;
$pink:    #e83e8c ;
$red:     #dc3545 ;
$orange:  #fd7e14 ;
$yellow:  #ffc107 ;
$green:   #28a745 ;
$teal:    #20c997 ;
$cyan:    #17a2b8 ;
$kodak:   #FAB617 ;

$colors: () ;
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:        #c2000b ;
$secondary:     $gray-600 ;
$success:       $green ;
$info:          $cyan ;
$warning:       $yellow ;
$danger:        $red ;
$light:         $gray-100 ;
$dark:          $gray-800 ;



// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) ;



// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
) ;



// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 ;
$grid-gutter-width:           30px ;
$grid-row-columns:            6 ;

//System

body {
  margin: 0;
  background-color: $white;
}

.page-title {
  position: fixed;
  width: calc(100vw - 2rem);
  padding: 1.5rem 1rem 1rem 1rem;
  z-index: 91;
  text-align: left;
  overflow: hidden;
}

.pagetitle {
  position: fixed;
  font-size: 1.75rem;
  padding: 1.5rem 1rem 1rem 1rem;
}

.page-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: var(--app-height);
  width: 100vw;
  z-index: 103;
  background-color: $primary;

  @-webkit-keyframes spinner {
    0% {-webkit-transform: rotate(0deg);transform: rotate(0deg)}
    100% {-webkit-transform: rotate(360deg);transform: rotate(360deg)}
  }

  @keyframes spinner {
    0% {-webkit-transform: rotate(0deg);transform: rotate(0deg)}
    100% {-webkit-transform: rotate(360deg);transform: rotate(360deg)}
  }

  .logo {
    position: relative;
    scale: 3;
    -webkit-animation: spinner 1300ms infinite;
            animation: spinner 1300ms infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    fill: $white
  }
}

.motion.page-loader {
  background-color: $motion;
  z-index: 103;

  .logo {
    fill: $primary
  }
}

.outlet {
  position: absolute;
  z-index: 101;
}